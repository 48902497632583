<template>
  <div>
    <v-chip class="mr-2 mb-2 topic-item" color="#E6FEFF" label>
      <div class="mr-2 mt-2" v-html="topicImg"></div>
      <span class="topic-text">{{ topic.toUpperCase() }}</span>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "TopicChip",
  props: {
    topic: { type: String, required: true },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      topicImg: "",
    };
  },
  computed: {
    topicsList() {
      return this.$store.getters["getHomeTopicsList"];
    },
  },
  methods: {
    loadImg() {
      let list = this.topicsList.filter((r) => {
        return this.topic.toLowerCase() == r.themeit.toLowerCase();
      });
      this.topicImg = list[0]?.svg ?? "";
    },
  },
  async created() {
    await this.loadImg();

    let iconList = document.querySelectorAll("svg");
    iconList.forEach((icon) => {
      icon.setAttribute("width", "20");
      icon.setAttribute("height", "20");
    });
  },
};
</script>

<style scoped lang="scss">
</style>
